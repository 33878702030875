import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Link from 'gatsby-link'
import React from 'react'
import { WorkTogetherCard } from '../components/WorkTogetherCard'
import get from 'lodash/get'
import {graphql} from "gatsby";

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const { previous, next } = this.props.pageContext

    return (
      <Layout>
        <section className="mb-4 blog-post__outer">
          <Helmet>
            <title>{`${post.frontmatter.title}`}</title>

            <meta property="og:title" content={`${post.frontmatter.title}`} />
            <meta property="og:description" content={`${post.excerpt}`} />
            <meta
              property="og:image"
              content={`https://browntreelabs.com/${post.frontmatter.image.childImageSharp.gatsbyImageData}`}
            />
            <meta name="twitter:description" content={`${post.excerpt}`} />
            <meta name="twitter:title" content={`${post.frontmatter.title}`} />
            <meta
              name="twitter:image"
              content={`https://browntreelabs.com/${post.frontmatter.image.childImageSharp.gatsbyImageData}`}
            />
          </Helmet>

          <div className="blog-post__title text-center jumbotron bg-light">
            <div className="container">
              <h1 className="font-weight-bold display-4">
                {post.frontmatter.title}
              </h1>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  padding: 0,
                  margin: 0,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <p className="text-secondary mb-1 pr-5">
                    📅 {post.frontmatter.date}
                  </p>
                  <p className="text-secondary">👷 Chris Power</p>
                </div>
              </div>
            </div>
          </div>

          <div className="container mb-5">
            <div className="row">
              <div className="col-md-10 offset-md-1 col-sm-12 blog-post">
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
              </div>
            </div>
            <div className="text-center mt-5">
              <WorkTogetherCard />
            </div>
          </div>
          <hr />
          <div className="container text-center mt-5">
            <h4>Like what you read?</h4>
            <h5 className="text-secondary font-italic">
              Check out some other posts
            </h5>
            <div className="card alert-info">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-sm-12 mb-2">
                    {previous && (
                      <Link
                        to={previous.fields.slug}
                        rel="prev"
                        className="btn btn-light"
                      >
                        👈 {previous.frontmatter.title.substring(0, 35)}
                      </Link>
                    )}
                  </div>
                  <div className="col-md-6 col-sm-12">
                    {next && (
                      <Link
                        to={next.fields.slug}
                        rel="next"
                        className="btn btn-light"
                      >
                        {next.frontmatter.title.substring(0, 35)} 👉
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      excerpt
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        image {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 400)
          }
        }
      }
    }
  }
`
